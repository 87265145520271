<template>
  <div class="modal-top fade show">
    <div class="modal-header">
      <h5 class="modal-title" :title="asset.title">{{ asset.title }}</h5>

      <div class="modal-metadata">
        <span
          class="channel-logo"
          :title="asset.channel.name"
          v-if="asset.channel"
        >
          <img
            :src="asset.channel.logo"
            class="img-logo"
            :alt="asset.channel.name"
            width="40"
            height="40"
          />
        </span>
        <i v-if="(live && !asset.isFakeProgram || (recordStatus && recordStatus!='FUTURO'&& recordStatus!='PASADO'))" class="zmdi zmdi-circle"></i>
          <span v-if="live && !asset.isFakeProgram" class="tag">en vivo</span>
          <span v-else-if="recordStatus === 'Recorded'" class="tag">Grabado</span>

        <i v-if="asset.certificateCode" class="zmdi zmdi-circle"></i>
        <span class="certificate-code" :title="asset.certificate">{{
          asset.certificateCode
        }}</span>
        <i v-if="asset.channel.lcn" class="zmdi zmdi-circle"></i>
        <span>CH {{ asset.channel.lcn }}</span>
        <i v-if="timeText || (asset.serie && asset.serie.idSerie)" class="zmdi zmdi-circle"></i>
        <span class="total-time">{{ timeText }}</span>
        <span v-if="asset.serie && asset.serie.idSerie" class="total-time"
          >Temporada: {{ asset.serie.temporada }} - Episodio:
          {{ asset.serie.episodio }}</span
        >
      </div>
    </div>

    <div class="modal-grid">
      <div class="modal-left">
        <div class="modal-poster">
          <img
            :src="urlPoster"
            width="700"
            height="400"
            :alt="asset.title"
            v-on:error="posterLoadError"
          />
          <ProgressBarLive
            v-if="live && !asset.isFakeProgram"
            :start="asset.startHourUnixTime"
            :end="asset.endHourUnixTime"
          />
        </div>
      </div>

      <div class="modal-right">
        <div class="modal-body">
          <div v-if="!asset.isFakeProgram" class="modal-detail">
            <p class="sinopsis">{{ synopsis }}</p>
            <p class="extra-data">
              <strong v-if="asset.director">Director:</strong>
              {{ asset.director }} <br />
              <strong v-if="asset.actors">Actores:</strong> {{ asset.actors }}
            </p>
          </div>

          <span class="shadow-fade"></span>

          <div class="modal-actions">
            <template
              v-if="asset.status != 'FUTURO' && asset.status != 'Planned'"
            >
              <a
                :disabled="!$listeners.play"
                :class="{ disabled: !$listeners.play }"
                v-on:click.prevent="play()"
                href="#"
                class="btn btn-primary"
                title="Reproducir esto..."
              >
                <i class="zmdi zmdi-play-circle-outline"></i>
                <span>{{ playTitle }}</span>
              </a>
            </template>

            <div v-if="recordOptions" class="popover-wrapper">
              <template
                v-if="!recordOptions.blocked && recordStatus === 'Recorded'"
              >
                <a
                  v-on:click.prevent="$emit('stop-recording')"
                  href="#"
                  class="btn btn-secondary btn-danger btn-record"
                  title="Eliminar grabación"
                >
                  <i class="zmdi zmdi-delete"></i>
                </a>
              </template>
            </div>

            <div v-if="asset.fechaInicio != 'Ayer'" class="popover-wrapper">
              <!-- <template v-if="!recordOptions.blocked && recordStatus==='Recorded'">
								<a v-on:click.prevent="$emit('stop-recording')" href="#" class="btn btn-secondary btn-danger btn-record" title="Eliminar grabación">
									<i class="zmdi zmdi-delete"></i>
								</a>
							</template> -->
              <template
                v-if="
                  !recordOptions.blocked &&
                  recordStatus === 'Planned' &&
                  recordOptions.grabado === true
                "
              >
                <a
                  v-on:click.prevent="$emit('stop-recording')"
                  href="#"
                  class="btn btn-secondary btn-danger btn-record"
                  title="Cancelar grabación"
                >
                  <i class="zmdi zmdi-time-interval"></i>
                </a>
              </template>
              <template
                v-else-if="
                  !recordOptions.blocked &&
                  recordStatus === 'Recording' &&
                  recordOptions.grabado === true
                "
              >
                <a
                  v-on:click.prevent="$emit('stop-recording')"
                  href="#"
                  class="btn btn-secondary btn-danger btn-record to-record record-confirmed"
                  title="Detener grabación"
                >
                  <i class="zmdi zmdi-dot-circle-alt"></i>
                </a>
              </template>
              <template v-else-if="recordOptions.blocked">
                <a
                  v-on:click.prevent="function () {}"
                  href="#"
                  class="btn btn-secondary btn-record disabled"
                  title="Opción no disponible temporalmente"
                >
                  <i class="zmdi zmdi-dot-circle"></i>
                </a>
              </template>
              <template
                v-if="
                  recordOptions.grabado === false && asset.status != 'PASADO'
                "
              >
                <a
                  v-on:click.prevent="recordOptions.show = !recordOptions.show"
                  href="#"
                  class="btn btn-secondary btn-record excludeDropdown"
                  :class="{ 'to-record': recordOptions.show }"
                  title="Programar grabación"
                >
                  <i
                    class="zmdi"
                    :class="
                      recordOptions.show
                        ? 'zmdi-time-interval'
                        : 'zmdi-dot-circle'
                    "
                  ></i>
                  <span class="sr-only">Grabar</span>
                </a>

                <div
                  class="popover-content popover-record fade"
                  :class="{ show: recordOptions.show }"
                >
                  <span class="popover-title excludeDropdown"
                    >Grabar este programa:</span
                  >

                  <form role="form" novalidate>
                    <div class="form-group excludeDropdown">
                      <label for="rangeInput">Desde antes del inicio</label>
                      <input
                        v-model="recordOptions.preGap"
                        id="rangeInput"
                        class="tplay-range excludeDropdown"
                        type="range"
                        min="0"
                        max="15"
                        step="5"
                        oninput="amount.value=rangeInput.value"
                      />
                      <input
                        v-model="recordOptions.preGap"
                        id="amount"
                        class="form-control excludeDropdown"
                        readOnly
                        type="text"
                        min="0"
                        max="15"
                        oninput="rangeInput.value=amount.value"
                      />
                      <span>min</span>
                    </div>

                    <div class="form-group excludeDropdown">
                      <label for="rangeInput2"
                        >Hasta después de que finalice</label
                      >
                      <input
                        v-model="recordOptions.postGap"
                        id="rangeInput2"
                        class="tplay-range excludeDropdown"
                        type="range"
                        min="0"
                        max="15"
                        step="5"
                        oninput="amount2.value=rangeInput2.value"
                      />
                      <input
                        v-model="recordOptions.postGap"
                        id="amount2"
                        class="form-control excludeDropdown"
                        readOnly
                        type="text"
                        min="0"
                        max="15"
                        oninput="rangeInput2.value=amount2.value"
                      />
                      <span>min</span>
                    </div>

                    <div
                      v-if="asset.serie && asset.serie.idSerie"
                      class="row-switch excludeDropdown"
                    >
                      <span>¿Todos los episodios?</span>
                      <div class="tplay-toggle excludeDropdown">
                        <input
                          v-model="recordOptions.switchSerie"
                          type="checkbox"
                          id="ModalHeaderTv-switchSerie"
                          class="excludeDropdown"
                        />
                        <label for="ModalHeaderTv-switchSerie">Toggle</label>
                      </div>
                    </div>

                    <div class="row excludeDropdown">
                      <button
                        v-on:click.prevent="$emit('start-recording')"
                        type="button"
                        class="btn btn-primary btn-sm"
                      >
                        OK
                      </button>
                    </div>
                  </form>
                </div>
              </template>
            </div>

            <span
              class="feedback-recording fade"
              v-if="recordStatus === 'Recording'"
              :class="{ show: recordOptions.grabado }"
            >
              Estamos grabando esto para tí.
            </span>
            <span
              class="feedback-recording fade"
              v-else-if="recordStatus === 'Planned'"
              :class="{ show: recordOptions.grabado }"
            >
              <i class="zmdi zmdi-check"></i>
              Listo: Grabaremos esto.
            </span>
            <span
              class="feedback-recording fade"
              v-if="recordStatus === 'Recorded'"
              :class="{ show: recordOptions.grabado }"
            >
              Eliminar grabación.
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import IMG_NO_POSTER_16_9 from "@/assets/images/no-poster-16-9.png";

const IMG_DEFAULT_ALPHA =
  "a5434fa604af965aa91eb3e32c523ae2f383bfdf6f6cbaa5682ed36a60b5b33e";

export default {
  name: "ModalHeaderTv",

  components: {
    ProgressBarLive: () => import("@/components/Progress-bar-live.vue"),
  },

  props: {
    asset: {
      type: Object,
      required: true,
    },
    recordOptions: {
      type: Object,
      required: false,
    },
    poster: {
      type: String,
      required: false,
    },
    posterMini: {
      type: String,
      required: false,
    },
    playTitle: {
      type: String,
      required: false,
      default: "Reproducir",
    },
    live: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  computed: {
    urlPoster() {
      const urlFromAlpha = this.asset.poster
        ? this.asset.poster.split("/").pop()
        : "";

      if (urlFromAlpha === IMG_DEFAULT_ALPHA || this.asset.isFakeProgram) {
        return IMG_NO_POSTER_16_9;
      } else {
        return this.asset.poster ? this.asset.poster : IMG_NO_POSTER_16_9;
      }
    },

    timeText() {
      const asset = this.asset;
      let text = "";

      if (asset.fechaInicio) {
        text += asset.fechaInicio;
      }
      if (asset.startHour && asset.endHour) {
        text += " de " + asset.startHour + " a " + asset.endHour + " hs.";
      }

      return text;
    },

    synopsis() {
      if (this.asset.synopsis) {
        return this.asset.synopsis;
      }

      if (this.asset.seasons && this.asset.chaptersCount) {
        return (
          this.asset.seasons.length +
          " temporadas, " +
          this.asset.chaptersCount +
          " episodios."
        );
      }

      return "Sin información.";
    },

    recordStatus() {
      if (this.asset.recordData != undefined) {
        return this.asset.recordData;
      } else {
        return this.asset.status;
      }
    },
  },

  methods: {
    posterLoadError(event) {
      event.target.src = "../assets/images/no-poster-5-7.png";
    },
    play() {
      let self = this;
      self.$emit("play");
      let butttonClose = document.querySelector(".close");
      butttonClose?.click();
    },
  },
};
</script>
